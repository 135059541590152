<template>
  <v-row class="fee-document">
    <v-col cols="6" class="py-0"
      ><v-select
        flat
        single-line
        label="Select Fee"
        :items="fees"
        item-text="name"
        item-value="id"
        v-model="reversal.product_id"
        :error-messages="isFeeFound ? '' : 'Error: Could not locate product.'"
        :hide-details="!isEditReversals"
        :readonly="isReadOnly"
      ></v-select
    ></v-col>
    <v-col cols="6" class="py-0"
      ><v-select
        flat
        single-line
        label="Select Document"
        :items="documents"
        item-text="name"
        item-value="id"
        v-model="reversal.document_id"
        :error-messages="isDocumentFound ? '' : 'Error: Could not locate document.'"
        :hide-details="!isEditReversals"
        :readonly="isReadOnly"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script type="text/babel">
export default {
  name: "FeeDocument",
  props: ["fees", "reversal", "documents", "isEditReversals", "isReadOnly"],
  data() {
    return {};
  },
  computed: {
    isFeeFound() {
      return this.fees.find(f => f.id === this.reversal.product_id) ? true : false;
    },
    isDocumentFound() {
      return this.documents.find(d => d.id === this.reversal.document_id) ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.fee-document .v-input {
  padding-top: unset !important;
  margin-top: unset !important;
}
</style>
