<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="3" class="ma-0 pa-0">
      <v-text-field
        id="hour"
        class="mt-0 pt-0"
        name="hour"
        placeholder="HH"
        v-model="time.hr"
        @change="convertToTimeString"
        data-vv-scope="timeSelector"
        data-vv-as="hour"
        type="number"
        min="1"
        max="12"
        v-validate="'required|numeric|max_value:12|min_value:0'"
        hide-details
        :error-messages="errors.collect('timeSelector.hour')"
      ></v-text-field>
    </v-col>
    <v-col cols="3" class="ma-0 pa-0 ml-2">
      <v-text-field
        id="minute"
        class="mt-0 pt-0"
        name="minute"
        placeholder="MM"
        v-model="time.min"
        @change="convertToTimeString"
        data-vv-scope="timeSelector"
        data-vv-as="minute"
        type="number"
        min="1"
        max="60"
        v-validate="'required|numeric|max_value:60|min_value:0'"
        hide-details
        :error-messages="errors.collect('timeSelector.minute')"
      ></v-text-field>
    </v-col>
    <v-col cols="5" class="ma-0 pa-0 pl-2">
      <v-select
        :items="['AM', 'PM']"
        id="ampm"
        name="ampm"
        v-validate="'required'"
        v-model="time.amPm"
        @change="convertToTimeString"
        data-vv-as="am pm"
        data-vv-scope="timeSelector"
        hide-details
        class="mt-0 pt-0"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script type="text/babel">

export default {
  name: 'TimeSelector',
  data() {
    return {
      time: {
        hr: '',
        min: '',
        amPm: ''
      }
    }
  },
  async created() {
    await this.convertToHoursAndMinutes();
  },
  computed: {
    getTimeString(){
      return this.settings.defaultCloseOfDay;
    }
  },
  methods:{
    convertToHoursAndMinutes() {
      let timeArray = this.getTimeString.split(":");
      this.time.amPm = "PM";
      this.time.hr = timeArray[0];
      if(timeArray[0] < 12) {
        this.time.amPm = "AM";
      } else {
        this.time.hr = (timeArray[0] == 12) ? timeArray[0] : timeArray[0] - 12;
      }
      this.time.min = timeArray[1];
    },
    convertToTimeString() {
      let newTimeString = {
        hr: '',
        min: ''
      }
      if(this.time.amPm == "PM") {
        newTimeString.hr = parseInt(this.time.hr) + 12;
      } else {
        newTimeString.hr = this.time.hr;
      }
      this.settings.defaultCloseOfDay = newTimeString.hr + ":" + this.time.min + ":00";
    }
  },
  props: {
    settings: {
      type: Object
    }
  },
  watch: {
    async "settings.defaultCloseOfDay"() {
      await this.convertToHoursAndMinutes();
    },
    "errors.items"(val) {
      this.$emit("setErrors", val);
    }
  }
};
</script>

<style lang="scss">
</style>