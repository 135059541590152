<template>
  <hb-modal
    v-model="dialog"
    size="large"
    :title="dialogTitle"
    @close="$emit('close')"
    :footerCancelOption="!editMode"
    show-help-link
  >
    <template v-slot:subheader>
      <span class="hb-text-light">
        {{ REVERSAL.SUBHEADER }} reversal.
      </span>
    </template>
    <template v-slot:content>

      <hb-form label="Type" full="true">
            <template v-slot:tooltipHeader>
              {{ REVERSAL.TYPE_DESC }}
            </template>
            <template v-slot:display>
              {{ REVERSAL.LABELS[reversal.type] }}
            </template>
        </hb-form>

        <hb-form label="Fee" full="true" @cancel="cancelEdit" @save="save" editable :empty="!reversal.product_id" :active.sync="active">
            <template v-slot:tooltipHeader>
              {{ REVERSAL.FEE_DESC }}
            </template>
            <template v-slot:display>
              {{ getReversalFee(reversal) }}
            </template>
            <template v-slot:edit>
              <hb-autocomplete
                        name="product_id"
                        label="Select a fee"
                        :items="fees"
                        :clearable="true"
                        :error-messages="errors.first('product_id') ? 'Please select a fee': ''"
                        :hide-details="!errors.has('product_id')"
                        data-vv-as="fee"
                        item-value="id"
                        item-text="name"
                        v-model="reversal.product_id">
              </hb-autocomplete>
            </template>
        </hb-form>
        <hb-form label="Document" full="true" @cancel="cancelEdit" @save="save" editable :empty="!reversal.document_id" :active.sync="active">
            <template v-slot:tooltipHeader>
              {{ REVERSAL.DOC_DESC }}
            </template>
            <template v-slot:display>
              {{ getReversalDocument(reversal) }}
            </template>
            <template v-slot:edit>
              <span class="hb-text-light mb-3 display-block">{{ REVERSAL.DOC_DESC_TITLE  }}</span>
              <hb-autocomplete
                        name="document_id"
                        label="Select a document"
                        :items="documents"
                        :clearable="true"
                        :error-messages="errors.first('document_id') ? 'Please select a document': ''"
                        :hide-details="!errors.has('document_id')"
                        data-vv-as="gl account"
                        item-value="id"
                        item-text="name"
                        v-model="reversal.document_id">
                  </hb-autocomplete>
            </template>
        </hb-form>

        <hb-form  v-if="!isPropertySelected" full="true" label="Email" @cancel="cancelEdit" @save="save" editable :empty="!reversal.email" :active.sync="active">
            <template v-slot:tooltipHeader>
              {{ REVERSAL.EMAIL_DESC }}
            </template>
            <template v-slot:display>
              {{ reversal.email.active ? 'Yes' : 'No' }}
            </template>
            <template v-slot:edit>
              <hb-switch @change="openEmail(reversal.email.active)" right v-model="reversal.email.active" :label="reversal.email.active ? 'Yes' : 'No'"></hb-switch>
                  <template v-if="reversal.email.active">
                                        <v-row>
                                          <v-col>
                                              <HbTextField
                                                  label="Subject"
                                                  v-model="reversal.email.subject"
                                                  v-validate="'required|max:78'"
                                                  id="subject"
                                                  name="subject"
                                                  hide-details
                                                  :error-messages="errors.first('subject')"
                                                  single-line
                                                  flat
                                                  class="input-font-size"
                                              />
                                            </v-col>
                                        </v-row>
                                        <v-row v-show="false">
                                            <HbTextarea
                                                label="Message"
                                                v-model="reversal.email.message"
                                                v-validate="'required'"
                                                id="message"
                                                name="message"
                                                hide-details
                                                :error-messages="errors.first('message')"
                                                flat
                                                rows="6"
                                                class="input-font-size"
                                            />
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <rich-text-editor 
                                                    v-model="reversal.email.message"
                                                />
                                           </v-col>
                                        </v-row>

                                        <v-row>
                                          <div>
                                            <v-col class="mt-0 mb-0 pt-0 pb-0">
                                                <hb-link v-show="showMergeFields === false" @click="showMergeFields = true">
                                                  Show Available Merge Fields 
                                                  <hb-tooltip>
                                                      <template v-slot:body>
                                                        <div style="max-width: 300px;">
                                                          Use Merge Fields in your message to insert
                                                          data about your tenants. By using Merge
                                                          Fields, you can send automated messages
                                                          to your tenants without having to manually
                                                          enter their information every time. Merge
                                                          Fields appear in brackets.
                                                          In order to use a merge field simply copy
                                                          and paste the text in brackets and add into
                                                          your message.
                                                          Example: Hello [Tenant.FirstName] [Tenant.LastName],
                                                          you owe [Tenant.TotalPastDue]. Please pay
                                                          by [Tenant.RentDueDate]. 
                                                        </div>
                                                      </template>
                                                  </hb-tooltip>     
                                                </hb-link>
                                                <hb-link v-show="showMergeFields === true" @click="showMergeFields = false">
                                                    Hide Available Merge Fields
                                                </hb-link> 
                                              </v-col>
                                           </div>
                                           <div>
                                              <v-col class="mt-0 mb-0 pt-0 pb-0">
                                                <merge-fields v-if="showMergeFields === true" /> 
                                              </v-col>
                                           </div>
                                        </v-row>
                                        
                    </template> 
            </template>
        </hb-form>
 
    </template>
    <template v-slot:actions>
      <hb-btn
        v-if="editMode"
        color="secondary"
        @click="$emit('close')"
      >
        Close
      </hb-btn>
      <hb-btn
        v-if="!editMode"
        color="primary"
        :disabled="isLoading($options.name)"
        @click="save"
      >
        Save
      </hb-btn>
      <span v-show="isLoading($options.name)">
        <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
      </span>
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Loader from "../../assets/CircleSpinner.vue";
import HoverableEditField from "../../assets/HoverableEditField.vue";  
import { EventBus } from '../../../EventBus.js'; 
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import RichTextEditor from '../../assets/RichTextEditor.vue';
import MergeFields from "../../includes/MergeFields.vue";
import { REVERSAL } from "./Constants.js";

export default {
  name: "EditReversal",
  data() {
    return {
      hoveredChip: null, 
      isEditReversal: false,
      adjustedOffset: 1, 
      reversal: {
        type: "",
        product_id: "",
        document_id: "",
        property_id: null,
        email: {
          active: false,
          subject: '',
          message: '', 
        },
      },
      activelyEditing: '',
      showMergeFields:false,
      active: '',
    };
  },
  mixins: [notificationMixin],
  components: {
    Loader,
    HoverableEditField,
    MergeFields,
    'rich-text-editor': RichTextEditor
  },
  props: ["selected","reversals","value","documents","fees","types","isPropertySelected","facilityID"],
  async created() {
    this.REVERSAL = REVERSAL;
    if (this.selected) { 
     await this.setReversalData();
    }
    EventBus.$on('activeEdit', this.setActiveEditName);
    EventBus.$on('saveData', this.save);
  },

  destroyed() {
    EventBus.$off('activeEdit', this.setActiveEditName);
    EventBus.$off('saveData', this.save);
  },

  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),

    editMode() {
      if (this.selected) {
        return true;
      }
      return false;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    dialogTitle() {
      let dialogueText = this.REVERSAL.DIALOGUE_TITLE;
      return `${dialogueText} ${this.$options.filters.capitalize(this.label)}`;
    },
  },

  methods: {
    setActiveEditName(name) {
      this.activelyEditing = name; 
    },

    async cancelEdit() {
        this.active = '';
        await this.setReversalData();
    },
    getReversalFee(reversal) {
      return this.fees.find(f => f.id === reversal.product_id) ? this.fees.find(f => f.id === reversal.product_id).name : 'No Fee';
    },
    getReversalDocument(reversal) {
      return this.documents.find(d => d.id === reversal.document_id) ? this.documents.find(d => d.id === reversal.document_id).name : 'No Document';
    },
    async setReversalData(){
      this.reversal.type = this.selected?.type;
      this.reversal.product_id = this.selected?.product_id;
      this.reversal.document_id = this.selected?.document_id;
      this.reversal.property_id = this.selected?.property_id;
      this.reversal.email.active = this.selected?.email?.active;
      this.reversal.email.subject = this.selected?.email?.subject;
      this.reversal.email.message = this.selected?.email?.message;
    },
    async save() {
      const validationStatus = await this.$validator.validateAll();
      if (validationStatus) {
        try {
          let data = this.getReversalData();
          let path = this.isPropertySelected ? api.PROPERTIES + this.facilityID + '/reversal' : api.BILLING + 'reversals';
          this.setActiveEditName('');
          await api.post(this, path, data).then(r => {
            let currentLocalDate = this.$options.filters.formatDateTimeCustom(
              new Date(),
              "MMM DD, YYYY @ h:mma."
            );
            this.active = '';
            let payload = {
              id: this.$options.name,
              type: 'success',
              description: `The reversal has been updated on ${currentLocalDate}`,
            };
            this.showMessageNotification(payload);
          }).catch(err => {
            this.active = '';
            this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
          });       
          this.$emit("refetch");
        } catch (err) {
          this.active = '';
          this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
        }
      }
    },
    getReversalData(){
      const res = {};
      this.reversals.forEach((item) => {
        if(item.type == this.reversal.type) {
          item = this.reversal;
        }
        if(this.isPropertySelected && item.email){
           delete item.email;
        }
        res[item.type] = item;
      });
      return res; 
    },
    openEmail(flag){
      flag ? this.reversal.email.active = true : this.reversal.email.active = false; 
    },
    async saveEmail(){
      const validationStatus = await this.$validator.validateAll();
      if(validationStatus){
        await this.save();
      }
    }
  },

  watch: {
    
  },
};
</script>

<style scoped>
.beginning-lease-text {
  font-weight: normal;
}

.param {
  background-color: white;
  border-radius: 10px;
  font-weight: 100;
}

.mrl--25px {
  margin-left: -25px;
  margin-right: -24px;
}
.key-label {
  background: #f4f6f8;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101318;
}
.top-border {
  border-top: 1px solid #dfe3e8;
}
.no-top-border {
  border-top: 0;
}
.border-bottom {
  border-bottom: 1px solid #dfe3e8;
}
.overflow-text {
  overflow-wrap: anywhere;
}
.scrollable-div {
  max-height: 350px;
  overflow: auto;
}
.promotion-icon {
  position: relative;
  top: 5px;
}
.no-border-section {
  border: 0 !important;
}
.edit-able-row:hover {
  background: #f4f6f8;
}
.icon {
  cursor: pointer;
}
.reversalType{
  display: block;
  padding: 14px 15px;
}
.display-block{
  display: block;
}
</style>
