<template>
  <draggable tag="div" :list="list" handle=".handle" :move="checkMove">
    <div v-for="(element, idx) in list" :key="idx">
      <v-row v-if="!hiddenItems.includes(element.toLowerCase())" class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" md="6">
          <v-row class="ma-0 pa-0 list-cell" :class="isDraggable({ futureIndex: idx }) ? 'handle grab' : ''">
            <v-col class="ma-0 pa-0" md="1">
              <hb-icon v-if="isDraggable({ futureIndex: idx })">mdi-drag-custom</hb-icon>
              <div class="mb-1" v-else><hb-icon>mdi-table-actions-disable</hb-icon></div>
            </v-col>
            <v-col class="ma-0 pa-0 text-capitalize">{{ element }}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </draggable>
</template>

<script type="text/babel">
import draggable from "vuedraggable";

export default {
  data() {
    return {};
  },
  components: {
    draggable
  },
  props: {
    // Can be refactored: list and nonDraggableIndexes can be converted to object with properties
    list: {
      type: Array,
      default: []
    },
    nonDraggableIndexes: {
      type: Array,
      default: []
    },
    hiddenItems: {
      type: Array,
      default: []
    }
  },
  methods: {
    checkMove(e) {
      return this.isDraggable(e.draggedContext);
    },
    isDraggable(context) {
      const { index, futureIndex } = context;
      return this.nonDraggableIndexes.includes(futureIndex) ? false : true; 
    }
  }
};
</script>

<style lang="scss" scoped>
.grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.list-cell {
    position: static !important;
    width: 100% !important;
    height: 28px !important;
    background: none !important;
    border: none !important;
}

</style>