<template>
  <div class="pa-0">
    <div class="interaction-section-body" @click="onClickAction()">
      <!-- Commented against INC-4471 -->
      <!-- <hb-form label="Close of Day" :description="active == 'Close of Day' ? 'Set a time when you want your properties to automatically close.' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable @editStart="editHasStarted" multiple>
        <template v-slot:display>
            {{convertToHoursAndMinutes}}
        </template>
        <template v-slot:edit>
          <v-row>
            <v-col cols="4" class="py-0">
              <time-selector :settings.sync="settings" @setErrors="setErrors"/>
              <div
                v-if="level === 'Property'"
                class="pt-3 hb-primary-color hand-pointer"
                @click="resetDefault"
              >
                Reset to Default
              </div>
            </v-col>
          </v-row>
        </template>
      </hb-form> -->
      <hb-form label="Payment Order" :description="active == 'Payment Order' ? 'Select the order in which these items will be charged. The top-most item is charged first.' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" :editable="hasBilligEditPermission" :empty="false" @editStart="editHasStarted" multiple>
        <template v-slot:display>
          <v-row class="pa-0 ma-0">
            <v-col class="ma-0 pa-0 text-capitalize" cols="12" sm="12">
              {{ getPaymentOrder }}
            </v-col>
            <v-col class="ma-0 pa-0 pt-2" cols="12" sm="12">
              <hb-icon color="#101318" v-if="settings.productLevelTax"> mdi-check </hb-icon>
              Apply payment to tax at product level
            </v-col>
          </v-row>
        </template>
        <template v-slot:edit>
          <draggable :list.sync="list" :nonDraggableIndexes="nonDraggableIndexes" :hiddenItems="hiddenProducts" />
          <div class="mt-2">
            <v-checkbox
              class="ml-1 my-0"
              v-model="settings.productLevelTax"
              flat
              hide-details
              id="promotion_enabled"
              name="promotion_enabled"
              single-line
              solo
            >
              <span slot="label" class="hb-text-night">
                Apply payment to tax at product level.
              </span>
            </v-checkbox>
          </div>
        </template>
      </hb-form>
      <hb-form label="Invoice Adjustment" :description="active == 'Invoice Adjustment' ? 'Select when you can no longer adjust an invoice.' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" :editable="hasBilligEditPermission" :empty="false" @editStart="editHasStarted">
        <template v-slot:display>
          <v-col class="ma-0 pa-0" cols="12" sm="12" v-if="rad == 'EOM'" >End of The Month</v-col>
          <v-col class="ma-0 pa-0" cols="12" sm="12" v-else >Number Of Days {{number_of_days}}</v-col>
        </template>
        <template v-slot:edit>
          <div>
            <v-radio-group
              dense
              solo
              flat
              hide-details
              v-model="rad"
              :mandatory="true"
            >
              <v-radio
                hide-details
                dense
                value="EOM"
              >
                <template v-slot:label>
                  <span class="hb-text-night">End Of The Month</span>
                </template>
              </v-radio>
              <v-radio hide-details value="nod">
                <template v-slot:label>
                  <div>
                    <v-row class="pa-0 ma-0">
                      <span class="mt-1 hb-text-night">
                        Number Of Days
                      </span>
                      <v-text-field
                        style="max-width: 50px;"
                        id="days"
                        class="mt-0 pt-0 ml-3 days-width"
                        name="days"
                        placeholder="Days"
                        data-vv-as="days"
                        type="number"
                        min="1"
                        v-model="number_of_days"
                        v-validate="rad != 'EOM' ? 'required|numeric|min_value:1' : ''"
                        hide-details
                        :error-messages="errors.collect('days')"
                      ></v-text-field>
                    </v-row>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </template>
      </hb-form>
      
      <hb-form label="Gift Card" :description="active == 'Gift Card' ? 'Display gift card method while taking payment.' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" :editable="hasBilligEditPermission" :empty="false" @editStart="editHasStarted">
        <template v-slot:display>
          {{ enableGiftCard ? 'Yes' : 'No' }}
        </template>
        <template v-slot:edit>
          <div>
            <hb-switch 
              v-model="enableGiftCard"
              :label="enableGiftCard ? 'Yes' : 'No'"
            />
          </div>
        </template>
      </hb-form>

      <hb-form :label=" getReversalThresholdTitle(reversal_type) + ' Reversal Threshold'" :description="active == 'Reversal Threshold' ? 'Select the number of days after which the reversal can be processed.' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" :editable="hasBilligEditPermission" :empty="false" @editStart="editHasStarted" v-for="(item,reversal_type) in REVERSALS" :key="reversal_type">
        <template v-slot:display>
          <div v-for="(value,key) in item" :key="key">
            <v-row class="ma-0">
              <v-col class="pa-0 pt-2" cols="3" sm="3" >
                {{`${value.text}: `}}
              </v-col>
              <v-col class="pa-0 pt-2" cols="3" sm="3" >
                {{`${reversal_thresholds[reversal_type][value.value]?reversal_thresholds[reversal_type][value.value]:REVERSALS[reversal_type][key]['reversal_threshold'] } ${reversal_thresholds[reversal_type][value.value] == 1 ? 'Day' : 'Days'}`}}
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:edit>
          <div v-for="(value,key) in item" :key="key">
            <v-row class="ma-0">
              <v-col class="pa-0 text-capitalize pt-2" cols="9" sm="9">
                {{`${value.text}`}}
              </v-col>
              <v-col class="pa-0" cols="3" sm="3">
                <hb-text-field
                  class="threshold-width"
                  type="number"
                  min="1"
                  max="500"
                  v-model="reversal_thresholds[reversal_type][value.value]"
                  v-validate="'required|numeric|min_value:1|max_value:500'"
                  hide-details
                  :error-messages="errors.collect(`${reversal_type}_${ value.value }`)"
                  :data-vv-as="reversal_type+' '+value.value"
                  :id="reversal_type + '_' +  value.value"
                  :name="reversal_type + '_' + value.value"
                ></hb-text-field>
              </v-col>
            </v-row>
          </div>
        </template>
      </hb-form>
    </div>
      
    <hb-global-notification
      v-model="snackbar"
      :type="type"
      :list="type === 'error' ? errorsList : []"
      :description="description"
    >
    </hb-global-notification>

    <payments-notification-modal
      v-model="notificationModal"
      notificationType="manage_settings_bill"
    >
    </payments-notification-modal>
    
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import HoverableEditField from "../../assets/HoverableEditField.vue";
import TimeSelector from "./TimeSelector.vue";
import Draggable from "./Draggable.vue";
import { EventBus } from '../../../EventBus.js';
import { mapGetters } from 'vuex';
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import REVERSAL from "@/constants/reversal.js"; 

export default {
  name: 'Transactional',
  mixins:[notificationMixin],
  data() {
    return {
      facilityLevelSection: true,
      activeTab: "tab-Corporate",
      // level: "Corporate",
      menuOptions: [
        {
          key: "Corporate",
          label: "Corporate Settings",
        },
        {
          key: "Property",
          label: "property Settings",
        }
      ],
      
      active: '',
      snackbar: false,
      notificationModal: false,
      type: '',
      description: '',
      errorsList: [],
      settings: {
        defaultCloseOfDay: '23:59:00',
        productLevelTax: false,
        invoiceAdjustmentDays: '',
      },
      rad: "EOM",
      number_of_days: '',
      enableGiftCard: '',
      list: ["auction", "rent", "fees", "insurance", "merchandise", "deposit"],
      hiddenProducts: ["inter_property_adjustment"],
      nonDraggableIndexes: [0],
      activelyEditing: "",
      editMode: true,
      property: null,
      selected: null,
      reversal_thresholds:{},
    };
  },
  async created() {
    this.REVERSALS = REVERSAL.REVERSALS;
    this.reversal_thresholds = this.getAllReversalKeys();
    await this.getSettings();
  },
  destroyed() {
    EventBus.$off('activeEdit', this.setActiveEditName);
    EventBus.$off('saveData', this.save);
  },
  methods: {

    tabChange(tabName){
      if (tabName == 'Corporate'){
        this.level = "Corporate";
        this.property = null;
        this.getSettings();
      }
      else{
        this.level = "Property";
        this.property = null;
        this.getSettings();
      }
    },

    setErrors(val) {
      if(val.length) {
        this.errorsList = val;
        // this.showNotification();
      }
    },
    showNotification() {
      this.description = "There are errors in your form, correct them before continuing."
      this.type = 'error';
      this.snackbar = true;
      return;
    },
    hideNotification() {
      this.errorsList = [];
      this.description = ""
      this.type = 'cancel';
      this.snackbar = false;
    },
    handleEmittedCancel(label){
      this.hideNotification();
      this.getSettings();
    },

    async handleEmittedSave(label){
      const valid = await this.$validator.validateAll();
      if (valid) {
        if(this.errors.items.length) return;
        this.save();
        this.active = 'saved';
      }
    },
    editHasStarted(label){
    },
    onClickAction(){
      if (!this.hasBilligEditPermission) {
        this.notificationModal = true;
      }
    },
    async resetDefault() {
      let defaultSettings = await api.get(this, api.SETTINGS + "transactional");
      if(defaultSettings.defaultCloseOfDay) {
        this.settings.defaultCloseOfDay = defaultSettings.defaultCloseOfDay;
      }
    },
    transformSettings(data) {
      this.settings = Object.assign({}, data);
      this.settings.productLevelTax = parseInt(data.productLevelTax);
      this.number_of_days = data.invoiceAdjustmentDays == 'EOM' ? 0 : data.invoiceAdjustmentDays;
      this.rad = data.invoiceAdjustmentDays == 'EOM' ? 'EOM' : 'nod';
      this.reversal_thresholds = data?.reversalThreshold ? JSON.parse(data.reversalThreshold) : this.getAllReversalKeys();
      this.getAllReversalKeys();
      this.list = data.paymentOrder.split(',');
      this.enableGiftCard = data.enableGiftCard == '1';
    },
    async getSettings() {
      if(this.isPropertySelected) {
        this.selected = await api.get(this, api.PROPERTIES + this.facilityID + '/transactional')
      } else {
        this.selected = await api.get(this, api.SETTINGS + "transactional");
      }
      if(this.isSettings) {
        this.transformSettings(this.selected);
      }
    },
    setActiveEditName(name) {
      this.activelyEditing = name;
    },
    async save() {
      let dragList = this.list.map(function(el) {
        return el;
      });
      if(this.isPropertySelected) {
        await api.post(this, api.PROPERTIES + this.facilityID +'/transactional', {
          defaultCloseOfDay: this.settings.defaultCloseOfDay ,
          paymentOrder: dragList.join(','),
          productLevelTax: this.settings.productLevelTax,
          invoiceAdjustmentDays: (this.rad == 'EOM') ? 'EOM' : this.number_of_days,
          reversalThreshold: JSON.stringify(this.reversal_thresholds),
          enableGiftCard: this.enableGiftCard ? '1' : '0'
        });
      } else {
        await api.post(this, api.SETTINGS + 'transactional', {
          defaultCloseOfDay: this.settings.defaultCloseOfDay ,
          paymentOrder: dragList.join(','),
          productLevelTax: this.settings.productLevelTax,
          invoiceAdjustmentDays: (this.rad == 'EOM') ? 'EOM' : this.number_of_days,
          reversalThreshold: JSON.stringify(this.reversal_thresholds),
          enableGiftCard: this.enableGiftCard ? '1' : '0'
        });
      }
      await this.getSettings();
      this.setActiveEditName('');
    },
		async propertyChanged(property) {
			this.property = property;
      this.getSettings();
		},
    getAllReversalKeys(){
      // get default values
      const reversal_types = Object.keys(REVERSAL.REVERSALS).reduce((a, v) => ({ ...a, [v]: {}}), {}) ;
      let reversal_meta = {};
      for(const rt in reversal_types){
        const child = {};
        for(const item in REVERSAL.REVERSALS[rt]){
          let obj = {};
          obj[REVERSAL.REVERSALS[rt][item]['value']] = REVERSAL.REVERSALS[rt][item]['reversal_threshold']
          Object.assign(child, obj);
        }
        reversal_meta[rt] = child;
      }
      return reversal_meta;
    }
	},
  computed: {
     ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    getDefaultCloseOfDay() {
      return this.settings.defaultCloseOfDay;
    },
    isSettings() {
      return this.selected && this.selected.defaultCloseOfDay ? true : false;
    },
    isPropertySelected() {
      return this.facilityID ? true : false;
    },
    convertToHoursAndMinutes() {
      let timeArray = this.settings.defaultCloseOfDay.split(":");
      let time = {}
      time.amPm = "PM";
      time.hr = timeArray[0];
      if(timeArray[0] < 12) {
        time.amPm = "AM";
      } else {
        time.hr = (timeArray[0] == 12) ? timeArray[0] : timeArray[0] - 12;
      }
      time.min = timeArray[1];
      return time.hr +":"+ time.min+time.amPm;
    },
    getPaymentOrder() {
      let paymentOrder = this.settings.paymentOrder ? this.settings.paymentOrder.replace(/,/g, ', ') : this.list.toString().replace(/,\s*([a-z])/g, function(d,e) { return ", "+e.toUpperCase() });
      paymentOrder = paymentOrder.replace(/, ?inter_property_adjustment,?/i, ''); 
      return paymentOrder;
    },
    hasBilligEditPermission(){
      return this.hasPermission("manage_settings_bill");
    },
    getReversalThresholdTitle(){
      return RT_type => {
        let title = RT_type.charAt(0).toUpperCase() + RT_type.slice(1);
        if(RT_type == 'ach') title = RT_type.toUpperCase();
        if(RT_type == 'giftcard') title = "Gift Card";
        return title;
      }
    }
  },
  components: {
    HoverableEditField,
    TimeSelector,
    Draggable,
    PaymentsNotificationModal
  },
  props: {
    level: {
      type: String,
      default: "",
    },
    facilityID: {
      type: String,
      default: ""
    }
  },
  watch: {
    "errors.items"(val) {
      if(val.length) {
        this.setErrors(val);
      } else {
        this.hideNotification();
      }
    },
    "facilityID"() {
      this.getSettings();
    }
  }
};
</script>

<style lang="scss">
.No-Property-Selected {
  height: 200px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #dfe3e8;
}

.interaction-section {
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px rgba(11, 18, 29, 0.1),
    0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
}

.interaction-section-body {
  background: white;
  height: auto;
}

.days-width {
	min-width: 50px;
}

.threshold-width {
	max-width: 50px;
  .corporate-property-tab{
        margin-bottom: 12px !important;
    }
}

</style>
<style lang="scss">
    .facility-dropdown{
        .v-input__slot {
            margin-top: 14px !important;
            margin-bottom: 14px !important;
        }
    }
</style>
