export const REVERSAL = Object.freeze({
  HEADERS: [
    {
      text: "Type",
      sortable: false,
      value: "type",
      class: "table-font-13"
    },
    {
      text: "Fee",
      sortable: true,
      value: "product_id",
      class: "table-font-13"
    },
    {
      text: "Document",
      sortable: false,
      value: "document_id",
      class: "table-font-13"
    },
    {
      text: "Email",
      sortable: false,
      value: "email",
      class: "table-font-13"
    }
  ],
  PROPERTY_HEADERS:[
    {
      text: "Type",
      sortable: false,
      value: "type",
      class: "table-font-13"
    },
    {
      text: "Fee",
      sortable: true,
      value: "product_id",
      class: "table-font-13"
    },
    {
      text: "Document",
      sortable: false,
      value: "document_id",
      class: "table-font-13"
    }
  ],
  LABELS:{
    'nsf': 'Check Reversal (NSF)',
    'chargeback':'Credit Card Chargeback',
    'ach':'ACH Reversal',
  },
  DIALOGUE_TITLE:"View/Edit Reversal",
  SUBHEADER:'View and edit your',
  TYPE_DESC:"The type determines the reversal type.",
  FEE_DESC:'The fee determines the reversal fee.',
  DOC_DESC:'The document determines the reversal document which is going to get generated.',
  EMAIL_DESC:'The email determines whether the tenant will receive email on reversal or not.',
  DOC_DESC_TITLE:'In case of multiple units in reversal, use the token [ReversalSpace.ID] in the document.',
  TYPES:["nsf", "chargeback", "ach"],
  SUBHEAD:'View and edit information about the reversal.',
  TOKEN_LIST:'Tokens List'
});
