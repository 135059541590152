<template>
  <div class="full-height">
    <!-- CHANGE ITTT!! -->
    <div class="pt-3 pb-4 px-4" v-if="(!readModePresent || activeEditing) && hasPermissionToEdit">
      <slot name="editingMode"></slot>
    </div>

    <div @click="setActiveField" class="hb-form-editable px-4 pt-3 pb-4 full-height" v-if="(readModePresent && !activeEditing)">
      <v-hover v-slot:default="{hover}">
      <v-row class="ma-0 py-0">
        <v-col md="11" class="ma-0 pa-0">
          <slot name="readMode"></slot>
        </v-col>
        <v-col md="1" class="ma-0 pa-0" v-if="hover && hasPermissionToEdit" align="right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{tooltipHover}">
                <span v-on="on">
                  <hb-icon small :color="tooltipHover ? '#101318' : ''">mdi-pencil</hb-icon>
                </span>
              </v-hover>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-hover>
    </div>

    <div class="mt-0 pb-4 px-5 d-flex align-center" v-if="readModePresent && activeEditing && displayActionButtons && hasPermissionToEdit">
      <hb-btn color="primary" @click="save" :small="true"> {{ saveButtonText }} </hb-btn>
      <hb-link class="ml-2" @click="cancel" :small="true"> Cancel </hb-link>
    </div>
  </div>
</template>

<script type="text/babel">
import { EventBus } from '../../EventBus.js';

export default {
  name: "HoverableEditField",

  data() {
    return {
      readModePresent: false
    }
  },

  created() {
    this.readModePresent = this.$slots.readMode ? true : false;
  },

  methods: {
    setActiveField() {
      if(this.hasPermissionToEdit) {
        EventBus.$emit('activeEdit', this.name);
      }
    },

    cancel() {
      EventBus.$emit('activeEdit', '');
      this.$emit('cancel', true)
    },

    save() {
      EventBus.$emit('saveData');
    }
  },

  props: {
    name: {
      type: String 
    },
    
    activeEditing: {
      type: Boolean,
      default: false
    },
    
    displayActionButtons: {
      type: Boolean,
      default: true
    },

    hasPermissionToEdit: {
      type: Boolean,
      default: true
    },

    saveButtonText: {
      type: String,
      default: 'Save'
    },
  },
};
</script>

<style>
.full-height {
  height: 100% !important;
}
</style>